<template>
  <div id="dict">
    <el-dialog
      :title="dictFormTitle"
      width="360px"
      :visible.sync="dictDialogVisible"
      :close-on-click-modal="false"
      @close="dictDialogClose"
    >
      <el-form
        ref="dictFormRef"
        :model="dictForm"
        :rules="dictFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="标签" prop="label">
          <el-input v-model="dictForm.label" placeholder="请输入标签" clearable />
        </el-form-item>
        <el-form-item label="编号" prop="code">
          <el-input v-model="dictForm.code" placeholder="请输入编号" clearable />
        </el-form-item>
        <el-form-item label="父标签" prop="pcode">
          <el-select v-model="dictForm.pcode" placeholder="请选择父标签" clearable>
            <el-option
              v-for="item in [{id: 0, label: '父标签', code: 0}, ...dictTree]"
              :key="item.id"
              :label="item.label"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="dictForm.sort" placeholder="请输入排序" controls-position="right" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dictDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="dictFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item>
        <el-button
          v-if="checkPermission(['DICT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="dictTableRef"
      :data="dictTree"
      :tree-props="{children: 'children'}"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
      @row-click="handleRowClick"
    >
      <el-table-column prop="label" label="标签" />
      <el-table-column prop="code" label="编号" />
      <el-table-column prop="sort" label="排序" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.pcode === 0 && checkPermission(['DICT_ADD'])"
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="handleAdd(scope.$index, scope.row)"
          >
            新增
          </el-button>
          <el-button
            v-if="checkPermission(['DICT_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['DICT_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  addDict,
  deleteDict,
  updateDict,
  selectDictInfo,
  selectDictTree
} from '@/api/system/dict'

export default {
  data () {
    return {
      dictDialogVisible: false,
      dictFormTitle: '',
      dictForm: {
        id: '',
        label: '',
        code: '',
        pcode: '',
        sort: ''
      },
      dictFormRules: {
        label: [{ required: true, message: '标签不能为空', trigger: ['blur', 'change']}],
        code: [{ required: true, message: '编号不能为空', trigger: ['blur', 'change']}],
        pcode: [{ required: true, message: '父标签不能为空', trigger: ['blur', 'change']}]
      },
      dictTree: []
    }
  },
  created () {
    selectDictTree().then(res => {
      this.dictTree = res
    })
  },
  methods: {
    dictDialogClose () {
      this.$refs.dictFormRef.resetFields()
    },
    dictFormSubmit () {
      this.$refs.dictFormRef.validate(async valid => {
        if (valid) {
          if (this.dictFormTitle === '新增字典') {
            await addDict(this.dictForm)
          } else if (this.dictFormTitle === '修改字典') {
            await updateDict(this.dictForm)
          }
          this.dictTree = await selectDictTree()
          this.dictDialogVisible = false
        }
      })
    },
    handleRowClick (row, column, event) {
      this.$refs.dictTableRef.toggleRowExpansion(row)
    },
    handleAdd (index, row) {
      if (row !== undefined) {
        this.dictForm.pcode = row.code
      }
      this.dictFormTitle = '新增字典'
      this.dictDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDict(row.id)
        this.dictTree = await selectDictTree()
      })
    },
    handleUpdate (index, row) {
      this.dictFormTitle = '修改字典'
      this.dictDialogVisible = true
      selectDictInfo(row.id).then(res => {
        this.dictForm.id = res.id
        this.dictForm.label = res.label
        this.dictForm.code = res.code
        this.dictForm.pcode = res.pcode
        this.dictForm.sort = res.sort
      })
    }
  }
}
</script>

<style>
</style>
